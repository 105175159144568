export const LINK_ON_HOVER = ":hover, :focus";
export const LINK_ACTIVE = ":active, .active, &.active";
export const LINK_DIRTY = LINK_ON_HOVER + ", " + LINK_ACTIVE;

export const ALL_HEADINGS = ["h1", "h2", "h3", "h4", "h5", "h6"];

export const STRETCHED = {
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  pointerEvents: "auto",
  content: '""',
  backgroundColor: "rgba(0,0,0,0)",
};

export function assignToArray(styleFn, arr) {
  return arr
    .map((x, idx) => ({ [x]: styleFn(idx) }))
    .reduce((acc, x) => Object.assign(acc, x), {});
}
