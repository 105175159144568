const stickProps = {
  display: "flex",
  position: [null, null, "sticky"],
  top: [null, null, 4],
  mt: [null, null, 4],
};

const layout = {
  grid: {
    prevNext: {
      "--numRows": 0, // set default
      display: "grid",
      gridTemplateColumns: [
        "1fr",
        null,
        "minmax(min-content, 20em) auto minmax(min-content, 20em)",
      ],
      gridTemplateRows: [
        "1fr repeat(var(--numRows), minmax(120px, auto))",
        null,
        "auto 1fr",
      ],
      gridGap: 4,
      pb: 4,
    },
    main: {
      gridRow: ["1", null, "1 / -1"],
      gridColumn: ["1", null, "2 / 3"],
      display: "flex",
    },
    prev: {
      ...stickProps,
    },
    next: {
      gridColumn: ["1", null, "-2 / -1"],
      ...stickProps,
    },

    site: {
      display: "grid",
      gridTemplateColumns: (t) => [
        "100%",
        `${t.sizes.smallSidebar} auto`,
        `${t.sizes.sidebar} auto`,
      ],
      gridTemplateRows: ["1fr auto auto", "1fr auto"],
      gridTemplateAreas: [
        `"main"
          "footer"
          "nav"`,
        `"nav main"
          "nav footer"`,
      ],
      minHeight: "100%", // sticky footer & navigation
    },
  },
};

export default layout;
