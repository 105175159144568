const zIndices = {
  backgroundImage: -10,
  overlay: -1,
  content: 10,
  cardStackTop: 21,
  tooltips: 22,
  footer: 100,
  navbar: 1010,
  dropdownList: 9100,
  pageOverlay: 9999,
};

export default zIndices;
