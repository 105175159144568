const SEPARATOR = "\\00BB";

const breadcrumbs = {
  list: {
    variant: "list.unstyled",
    display: "flex",
    flexWrap: "wrap",
    px: [3, 4],
    py: [2, 3],
    mb: 4,
    mx: [-3, -4],
    mt: [-3, -2],
    backgroundColor: "shade3",
    borderBottom: (t) => `1px solid ${t.colors.shade3}`,
  },

  item: {
    ":not(:first-of-type)": {
      pl: 2,
    },
    ":not(:first-of-type)::before": {
      display: "inline-block",
      pr: 2,
      color: "shade6",
      content: `'${SEPARATOR}'`,
    },
  },
};

export default breadcrumbs;
