const pagination = {
  display: "flex",
  my: 4,
  pl: 0,
  listStyle: "none",
  flexWrap: "wrap",
  justifyContent: "center",

  item: {
    py: 3,
    px: 4,
    display: "block",
    border: (t) => `1px solid ${t.colors.shade5}`,
    bg: "lighten3",
  },

  activeItem: {
    variant: "pagination.item",
    bg: "shade4",
  },
};

export default pagination;
