const prevNext = {
  box: {
    display: "flex",
    flex: "1 1", // ensures width of all PrevNextPage-elements to be equal on mobile devices
    border: (t) => [`1px solid ${t.colors.shade5}`, null, 0],
    borderRadius: "small",
    backgroundColor: ["shade2", null, "unset"],
    alignItems: "center",
    py: 2,
    px: 3,

    position: "relative",
    "&:active": {
      top: 1,
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1",
    overflow: [null, null, "hidden"],
    hyphens: [null, null, "auto"],
  },
  text: {
    fontWeight: 600,
  },
  meta: {
    fontStyle: "italic",
  },
  date: {
    fontStyle: "italic",
  },
};

export default prevNext;
