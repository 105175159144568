import { LINK_DIRTY, LINK_ON_HOVER } from "../helpers";

const makeVariant = (variant = "primary") => ({
  position: "relative",
  my: 2,
  px: 4,
  py: 2,
  color: "lightText",
  backgroundColor: variant,
  border: (t) => `2.5px solid ${t.colors.shade5}`,
  borderRadius: "round",
  fontWeight: "bold",
  outline: "none",
  transition: "all 100ms ease-in",
  "&:hover": {
    cursor: "pointer",
  },
  "&:active, &:hover, &:focus": {
    borderColor: variant,
    color: variant,
    bg: (t) => `${t.rawColors.background}bb`,
  },
  "&:active": {
    top: 1,
  },
  "&:focus": {
    boxShadow: (t) => `0 0 0 .2rem ${t.rawColors[variant]}60`,
  },
});

const buttons = {
  primary: {
    ...makeVariant(),
  },
  secondary: {
    ...makeVariant("secondary"),
  },
  icon: {
    bg: "transparent",
    border: 0,
    color: "shade8",
    [LINK_ON_HOVER]: {
      cursor: "pointer",
      color: "primary",
    },
  },
  navbar: {
    display: ["none", "flex"],
    flexDirection: ["column", null, "row"],
    alignItems: "center",
    justifyContent: "space-between",
    m: 3,
    mt: 0,
    ":first-of-type": {
      mt: 3,
    },
    p: 3,
    fontSize: [5, null, 2],
    color: "lightText",
    bg: "shade4",
    borderRadius: "medium",
    [LINK_DIRTY]: {
      borderColor: ["accent", "lighten4"],
      color: "accent",
    },
    [LINK_ON_HOVER]: {
      cursor: "pointer",
      bg: [null, "lighten4"],
    },
    "> .label": {
      display: ["none", null, "inline"],
    },
  },
  navbarSearch: {
    variant: "buttons.navbar",
    bg: ["shade4", "transparent"],
    borderBottom: (t) => [null, null, `1px solid ${t.colors.lighten5}`],
    justifyContent: "flex-start",
    gap: 3,
  },
  mobileBar: {
    display: "flex",
    justifyContent: "space-between",
    color: "text",
    bg: "transparent",
    borderRadius: 0,
    [LINK_ON_HOVER]: {
      bg: "shade4",
      cursor: "pointer",
    },
    "> .label": {
      pr: 2,
    },
  },
};

export default buttons;
