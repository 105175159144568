const NAV_ARROW_WIDTH_PX = 16;
const NAV_ARROW_DEPTH_PX = 10;

const layout = {
  nav: {
    position: "sticky",

    bottom: [0, "unset"],
    top: [null, 0],

    width: ["100%", "unset"],
    height: [null, "100vh"],

    display: [null, "flex"],
    flexDirection: [null, "column"],
    overflowY: [null, "auto"],

    backgroundColor: (t) => ["contrast", `${t.rawColors.contrast}ea`],
    boxShadow: (t) => [`0 0 2px ${t.colors.shade6}`, "none"],
    color: "lightText",

    brand: {
      display: ["none", "flex"],
      py: [null, 3],
      px: [null, 3, 4],
      color: "lightText",
      fontFamily: "heading",
      fontWeight: "bold",
      lineHeight: "siteTitle",
      fontSize: 5,
      fontVariant: "small-caps",
      alignItems: "center",
      alignSelf: "center",
      gap: 3,
      "> span": {
        display: ["none", "none", "flex"],
      },
      bg: "darken5",
    },

    inlineBrand: {
      display: "flex",
      flexDirection: "column",
      py: 1,
    },

    list: {
      variant: "list.unstyled",
      display: "flex",
      flexFlow: ["row nowrap", "column nowrap"],
      alignContent: "center",
      flex: [null, "1 1"],
      mb: [null, null, 4],
    },

    item: {
      textAlign: [null, "right"],
      display: "flex",
      flex: ["1 1", "0 0"],
      flexFlow: "column nowrap",
      justifyItems: "stretch",
      borderRight: (t) => [`1px solid ${t.colors.lighten3}`, "unset"],
      ":last-of-type": {
        borderRight: 0,
      },
    },

    activeItem: {
      variant: "layout.nav.item",
      position: "relative",
      backgroundColor: "contrast",
      transformStyle: "preserve-3d",

      "::before": {
        // This is the border of the arrow shape.
        content: ["''", "unset"],
        position: "absolute",
        top: `-${(NAV_ARROW_WIDTH_PX + 2) / 2}px`,
        left: `calc(50% - ${NAV_ARROW_WIDTH_PX + 2}px)`,
        borderBottom: (t) =>
          `${NAV_ARROW_DEPTH_PX + 2}px solid ${t.colors.shade6}`,
        borderRight: `${NAV_ARROW_WIDTH_PX + 2}px solid transparent`,
        borderLeft: `${NAV_ARROW_WIDTH_PX + 2}px solid transparent`,
        transform: "translateZ(-1px)",
      },
      "::after": {
        content: ["''", "unset"],
        position: "absolute",
        top: `-${NAV_ARROW_WIDTH_PX / 2}px`,
        left: `calc(50% - ${NAV_ARROW_WIDTH_PX}px)`,
        borderBottom: (t) =>
          `${NAV_ARROW_DEPTH_PX}px solid ${t.colors.contrast}`,
        borderRight: `${NAV_ARROW_WIDTH_PX}px solid transparent`,
        borderLeft: `${NAV_ARROW_WIDTH_PX}px solid transparent`,
      },
    },

    iconSpacer: {
      pl: [null, null, 3],
    },
  },
};

export default layout;
