const list = {
  unstyled: {
    listStyle: "none",
    m: 0,
    p: 0,
  },
  inline: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    m: 0,
    p: 0,
  },
  badges: {
    variant: "list.inline",
    "> li": {
      py: 1,
      px: 3,
      mr: 2,
      mt: 2,
      border: (t) => `1px solid ${t.colors.shade5}`,
      borderRadius: "medium",
    },
  },
};

export default list;
