// Theme specifications are defined in extra files to make
// it easier to look-up specific theme keys in a fast way.
import { merge } from "theme-ui";
import colors from "./colors";
import breakpoints from "./breakpoints";
import sizes from "./sizes";
import radii from "./radii";
import zIndices from "./zIndices";
import {
  fonts,
  fontSizes,
  fontWeights,
  lineHeights,
  text,
  heading,
  styles as typographyStyles,
} from "./typography";
import list from "./variants/list";
import buttons from "./variants/buttons";
import links from "./variants/links";
import cards from "./variants/cards";
import breadcrumbs from "./variants/breadcrumbs";
import pagination from "./variants/pagination";
import srOnly from "./variants/srOnly";
import prevNext from "./variants/prevNext";
import navLayout from "./layout/nav";
import gridLayout from "./layout/grid";

const styles = {
  root: {
    m: 0,
    color: "text",
    backgroundColor: "background",
  },
  p: { m: 0 },
  h1: { m: 0 },
  h2: { m: 0 },
  h3: { m: 0 },
  h4: { m: 0 },
  h5: { m: 0 },
  h6: { m: 0 },
  a: {
    variant: "links.default",
  },
  table: {
    borderCollapse: "collapse",
    mb: 4,
    width: "100%",
  },
  th: {
    verticalAlign: "bottom",
    backgroundColor: "shade3",
    border: (t) => `1px solid ${t.colors.shade5}`,
    borderLeft: 0,
    borderRight: 0,
    p: 2,
  },
  td: {
    verticalAlign: "top",
    backgroundColor: "shade1",
    p: 2,
  },
};

export const theme = {
  initialColorModeName: "light",
  breakpoints,
  radii,
  sizes,
  fonts,
  fontSizes,
  fontWeights,
  text,
  colors,
  heading,
  lineHeights,
  zIndices,
  styles: merge(styles, typographyStyles),
  layout: merge(navLayout, gridLayout),
  buttons,
  links,
  list,
  cards,
  breadcrumbs,
  pagination,
  srOnly,
  prevNext,
};

export default theme;
