import { LINK_ON_HOVER, LINK_ACTIVE, LINK_DIRTY } from "../helpers";
import { complement } from "@theme-ui/color";

const links = {
  default: {
    color: "link",
    textDecoration: "none",
    [LINK_DIRTY]: {
      color: "primary",
      borderColor: "primary",
    },
  },
  light: {
    color: "accent",
    textDecoration: "none",
    [LINK_DIRTY]: {
      color: complement("accent"),
      borderColor: complement("accent"),
    },
  },
  unstyled: {
    color: "text",
    textDecoration: "none",
  },
  nav: {
    border: "unset",
    display: "flex",
    flexDirection: ["column", null, "row"],
    alignItems: "center",
    px: [2, null, 4],
    py: 3,
    lineHeight: "1.3",

    whiteSpace: "nowrap",
    overflow: "hidden",

    fontSize: [1, 3],
    textDecoration: "none",
    color: "lightText",
    borderBottom: (t) => [
      `2px solid transparent`,
      `unset`,
      `1px solid ${t.colors.lighten4}`,
    ],
    borderTop: (t) => [null, null, `1px solid ${t.colors.lighten4}`],
    [LINK_DIRTY]: {
      borderColor: ["accent", "lighten4"],
      color: "accent",
    },
    [LINK_ON_HOVER]: {
      bg: [null, "lighten4"],
    },
  },
  footerNav: {
    textDecoration: "none",
    color: "lightText",
    [LINK_ACTIVE]: {
      color: "accent",
    },
    [LINK_ON_HOVER]: {
      color: "accent",
    },
  },
  card: {
    variant: "links.default",
    border: "unset",
    position: "relative",
    "&:active": {
      top: 1,
    },
    "> div": {
      transition: "all .2s ease-out, border-color .2s linear, color .2s linear",
      [LINK_ON_HOVER]: {
        variant: "links.default",
      },
      [LINK_DIRTY]: {
        position: "relative",
        transform: "scale(1.02)",
      },
    },
  },
  dropdown: {
    variant: "links.default",
    py: 2,
    px: 3,
    borderBottom: (t) => `1px solid ${t.colors.shade4}`,
    [LINK_DIRTY]: {
      color: "primary",
      borderColor: "shade4",
      bg: "shade3",
    },
  },
};

export default links;
