import { ALL_HEADINGS, assignToArray } from "./helpers";

export const fontWeights = {
  body: 400,
  heading: 600,
  bold: 700,
  xbold: 900,
  siteTitle: 700,
  lead: 400,
};

export const fontSizes = [
  "0.85rem",
  "0.9rem",
  "1.0rem",
  "1.125rem",
  "1.25rem",
  "1.5rem",
  "2rem",
  "3.5rem",
  "5rem",
];

export const fonts = {
  body: 'Archivo, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  heading:
    'Raleway, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
};

export const lineHeights = {
  body: 1.75,
  label: 1.3,
  heading: 1.25,
  siteTitle: 0.95,
};

export const text = {
  default: {
    fontSize: 2,
    fontFamily: "body",
    lineHeight: "body",
  },
  heading: {
    fontFamily: "heading",
    fontWeight: "heading",
    lineHeight: "heading",
  },
  siteTitle: {
    fontSize: 7,
    fontFamily: "heading",
    fontWeight: "siteTitle",
    fontVariant: "small-caps",
    lineHeight: "siteTitle",
  },
  label: {
    fontSize: 2,
    fontFamily: "body",
    lineHeight: "label",
    fontWeight: "heading",
  },
  lead: {
    fontSize: 4,
    fontWeight: "lead",
  },
  paragraph: {
    variant: "text.default",
    mt: 0,
    mb: 4,
  },
  copyrightNotice: {
    variant: "text.default",
  },
};

export const heading = {
  fontFamily: "heading",
  fontWeight: "heading",
  lineHeight: "heading",
};

export const styles = {
  root: {
    fontFamily: "body",
    fontWeight: "body",
    lineHeight: "body",
  },
  p: {
    variant: "text.paragraph",
    mt: 0,
    mb: 3,
    fontSize: 2,
    lineHeight: "body",
  },
  ...assignToArray(
    (idx) => ({
      variant: "text.heading",
      fontSize: 7 - idx,
      mx: 0,
      mb: 3,
      mt: 0,
    }),
    ALL_HEADINGS
  ),
};

export default styles;
