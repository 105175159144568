const srOnly = {
  border: 0,
  clip: "rect(0,0,0,0)",
  height: "1px",
  margin: "-1px",
  overflow: "hidden",
  p: 0,
  position: "absolute",
  width: "1px",
  whiteSpace: "nowrap",
};

export default srOnly;
