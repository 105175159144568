import breakpoints from "../breakpoints";
import { LINK_DIRTY } from "../helpers";

const cards = {
  default: {
    p: 3,
    height: "100%",

    backgroundColor: "lighten4",
    border: (t) => `1px solid ${t.colors.shade5}`,
    borderRadius: "medium",
    boxShadow: (t) => `0 .125rem .25rem ${t.colors.shade5}`,

    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },

  labelled: {
    // (e.g. alarms)
    variant: "cards.default",
    p: 0,
    flex: "1 1",
  },

  vehicle: {
    // border: (t) => `1px solid ${t.colors.shade5} !important`,
    boxShadow: (t) => `0 .125rem .25rem ${t.colors.shade5}`,
    background: (t) => [
      `${t.rawColors.background2}cc`,
      `${t.rawColors.background2}`,
    ],

    display: "grid",
    gridTemplateColumns: "minmax(auto, 320px) 1fr",
    height: "100%",
    width: "100%",
    borderRadius: "medium",

    container: {
      "> *": {
        borderTopLeftRadius: "medium",
        borderBottomLeftRadius: "medium",
        borderTopRightRadius: ["medium", "unset"],
        borderBottomRightRadius: ["medium", "unset"],
      },
      gridRow: [1, "unset"],
      gridColumn: ["1 / -1", "unset"],
    },

    aside: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      justifyContent: ["flex-end", "center"],

      p: 3,
      background: (t) => [
        `${t.rawColors.background2}cc`,
        `${t.rawColors.background2}`,
      ],
      color: "lightText",
      border: (t) => `8px solid ${t.colors.shade4}`,

      gridRow: [1, "unset"],
      gridColumn: ["1 / -1", "unset"],
      zIndex: "cardStackTop",

      borderTopLeftRadius: ["medium", "unset"],
      borderBottomLeftRadius: ["medium", "unset"],
      borderTopRightRadius: "medium",
      borderBottomRightRadius: "medium",
    },

    link: {
      variant: "links.card",
      [LINK_DIRTY]: {
        ".hoverStyles": {
          backgroundColor: ["darken7", "primaryBackground"],
          boxShadow: (t) => [null, `0 0 1rem ${t.colors.shade4} inset`],
          borderColor: [null, "shade5"],
          transition: "all .2s ease-out",
        },
      },
    },
  },

  sideLabelled: {
    variant: "cards.labelled",
    justifyContent: "flex-start",
    flexFlow: ["column nowrap", "row nowrap"],
  },

  frostedGlass: {
    borderRadius: "medium",
    border: (t) => `2px solid ${t.colors.shade5}`,
    boxShadow: (t) => `.05rem .1rem 1rem 0 ${t.colors.shade4}`,
    backgroundColor: (t) => `${t.rawColors.background}40`,
    backdropFilter: "blur(10px)",

    overflow: "hidden",
    display: "flex",
    flex: "1 1 22em",
    mx: 2,
    height: "100%",
  },

  sideHeader: {
    variant: "text.label",
    transform: "rotate(-180deg)",
    writingMode: "vertical-rl",
    textAlign: "center",
    px: 2,
    py: 3,
    display: "flex",
    alignSelf: "stretch",
    placeContent: "center",
    opacity: 0.6,
    "> *": {
      height: "min-content",
    },
  },

  responsiveSideHeader: {
    transform: [null, "rotate(-180deg)"],
    writingMode: [null, "vertical-rl"],
    textAlign: "center",
    px: 2,
    py: 3,
    fontSize: 2,
    bg: "shade3",
  },

  container: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
    py: 3,
    px: 3,
    backgroundColor: (t) => `${t.rawColors.background}40`,
  },

  footer: {
    borderTop: (t) => `1px solid ${t.colors.shade5}`,
    pt: 1,
  },

  events: {
    link: {
      variant: "links.card",
      [LINK_DIRTY]: {
        color: "primary",
        ".hoverStyles": {
          bg: (t) => `${t.rawColors.primary}d0`,
          transition: "all .2s ease-out",
        },
      },
    },
    container: {
      overflow: "hidden",
      hyphens: "auto",
      p: 3,
      display: "flex",
      flexFlow: "column wrap",
      justifyContent: "space-between",
    },
    header: {
      display: "flex",
      flexFlow: "column wrap",
      bg: (t) => `${t.rawColors.secondary}d0`,
      color: "lighten7",
      borderTopLeftRadius: "medium",
      borderBottomLeftRadius: [null, "medium"],
      borderTopRightRadius: ["medium", "unset"],
      justifyContent: "center",
    },
    datesContainer: {
      display: "flex",
      py: [2, 3],
      pl: 3,
      pr: [3, 4],
    },
    dateContainer: {
      position: "relative",
      display: ["flex", "block"],
      flex: "1 1",
      flexWrap: "wrap",
      justifyContent: "center",
    },
    date: {
      // > lg
      [`@media screen and (min-width: ${breakpoints[0]})`]: {
        lineHeight: 0,
        fontSize: 5,
        pr: 0,
        ":last-of-type": {
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "center",
          transform: "scale(1.05) translate(32.6%, -5%)",
          color: "shade6",
          WebkitTextStroke: (t) => `1px ${t.colors.unshade5}`,
        },
        ":nth-of-type(2)": {
          fontSize: 7,
          lineHeight: "75%",
        },
        ":nth-of-type(3)": {
          fontSize: 6,
        },
      },
      // > xs
      fontVariant: "small-caps",
      fontWeight: "bold",
      fontSize: 6,
      pr: 2,
      ":last-of-type": {
        pr: 0,
      },
    },
    time: {
      borderTop: (t) => `1px solid ${t.colors.lighten5}`,
      p: 2,
      px: 3,
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    divider: {
      textTransform: "uppercase",
      transform: "rotate(-180deg)",
      writingMode: "vertical-rl",
      color: "shade5",
      fontWeight: "bold",
      textAlign: "center",
      py: 2,
      fontSize: 4,
    },
  },
};

export default cards;
