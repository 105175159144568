const MAX_SHADES = 8;

// darken1 - darken{MAX_SHADES}
const darkenLight = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`darken${i + 1}`]: `rgb(0 0 0 / ${Math.min(2 ** i, 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

const darkenDark = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`darken${i + 1}`]: `rgb(0 0 0 / ${Math.min(2 ** i, 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

// lighten1 - lighten{MAX_SHADES}
const lightenLight = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`lighten${i + 1}`]: `rgb(255 255 255 / ${Math.min(2 ** i, 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

const lightenDark = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`lighten${i + 1}`]: `rgb(255 255 255 / ${Math.min(2 ** i, 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

// shade - shade{N}
const shadeLight = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`shade${i + 1}`]: `rgb(0 0 0 / ${Math.min(2 ** i, 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

const shadeDark = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`shade${i + 1}`]: `rgb(255 255 255 / ${Math.min(2 ** (i - 1), 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

// unshade - unshade{N}
const unshadeLight = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`unshade${i + 1}`]: `rgb(255 255 255 / ${Math.min(2 ** i, 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

const unshadeDark = Array.from({ length: MAX_SHADES }, (_, i) => ({
  [`unshade${i + 1}`]: `rgb(0 0 0 / ${Math.min(2 ** i, 80)}%)`,
})).reduce((a, b) => Object.assign(a, b), {});

const colors = {
  primary: "#d12b2b",
  primaryBackground: "#d55053",
  secondary: "#5171A5",
  link: "#073b73",
  text: "#333",
  muted: "#808080",
  lightText: "#edf2f4",
  accent: "#f9f6c9",
  background: "#ebf3fb",
  background2: "#2f4b75",
  gray: "#525558",
  contrast: "#192231",
  scrollbarThumb: "#9fa5aa",
  scrollbarTrack: "#c6d0d4",

  // Only used for legacy event labels:
  // success: "#348034",
  // info: "#8d4daf",

  alarmTypeH: "#5a7bf2ea",
  alarmTypeF: "#f25b50ea",
  alarmTypeS: "#6a7880ea",

  ...darkenLight,
  ...lightenLight,
  ...shadeLight,
  ...unshadeLight,

  modes: {
    dark: {
      primary: "#e24646",
      primaryBackground: "#ba3d42",
      secondary: "#396abb",
      link: "#abc7e4",
      text: "#daecfd",
      lightText: "#d6dbde",
      accent: "#f9f6c9",
      background: "#0b162f",
      background2: "#172642",
      gray: "#19242f",
      contrast: "#091125",
      scrollbarThumb: "#303b50",
      scrollbarTrack: "#121c2f",

      alarmTypeS: "#c95bb4ea",

      ...darkenDark,
      ...lightenDark,
      ...shadeDark,
      ...unshadeDark,
    },
  },
};

export default colors;
